import React, { useState } from "react"
import { navigate } from "gatsby"

const OptInForm = ({ isCabinetVirtualForm = false }) => {
  const [optInState, setOptInState] = useState({
    name: "",
    phone_number: "",
    email: "",
  })

  const handleInputChange = e => {
    const key = e.target.name
    const value = e.target.value
    setOptInState({
      ...optInState,
      [key]: value,
    })
  }

  const submitForm = e => {
    e.preventDefault()
    if (window.gtag) {
      window.gtag("event", "conversion", {
        send_to: "AW-10865682082/yugPCKGB5KgDEKLNlL0o",
      })
    }

    if (typeof window !== "undefined") {
      if (window.fbq != null) {
        window.fbq("track", "Lead", { currency: "USD" })
      }
    }

    fetch(`${process.env.GATSBY_ZONUM_API_URL}/lead`, {
      method: "POST",
      body: JSON.stringify({
        ...optInState,
        status: "New Lead",
      }),
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        ARN:
          " arn:aws:execute-api:us-east-1:074778375650:qgdj8hao2d/*/POST/lead",
        Auth: "NONE",
      },
    })

    if (window && window.localStorage) {
      window.localStorage.setItem(
        "infoForSignIn",
        JSON.stringify({
          userInfo: {
            email: optInState.email,
            name: optInState.name,
            formLastSent: new Date().toString(),
          },
        })
      )
    }

    navigate(
      isCabinetVirtualForm ? "/cabinet-virtual-quote/" : "/request-a-quote/",
      {
        state: {
          name: optInState.name,
          phone_number: optInState.phone_number,
          email: optInState.email,
        },
      }
    )
  }

  return (
    <div className="optInContainer">
      <div className="optInForm">
        <p className="optInFormHeader">
          {isCabinetVirtualForm
            ? "Want A Free Virtual Cabinet Painting Quote?"
            : "Ready to transform your home?"}
        </p>
        <p style={{ textAlign: "center" }}>
          Request Your <strong>FREE</strong> Quote Below
        </p>
        <form onSubmit={e => submitForm(e)}>
          <div className="optInInput">
            <label>Name</label>
            <input
              name="name"
              onChange={handleInputChange}
              value={optInState.name}
              type="text"
            />
          </div>
          <div className="optInInput">
            <label>Phone Number</label>
            <input
              name="phone_number"
              onChange={handleInputChange}
              value={optInState.phone_number}
              type="text"
            />
          </div>
          <div className="optInInput">
            <label>Email</label>
            <input
              name="email"
              onChange={handleInputChange}
              value={optInState.email}
              type="email"
            />
          </div>
          <input
            className="optInButton"
            type="submit"
            name="submit"
            value={isCabinetVirtualForm ? "Continue" : "Book Your Quote"}
          />
        </form>
      </div>
    </div>
  )
}

export default OptInForm
