import Layout from "../components/layout"
import Hero from "../components/hero"
import React from "react"
import Reviews from "../components/reviews"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import TwentyTwenty from "react-twentytwenty"
import OptInForm from "../components/optInForm"
import * as ServicePageStyles from "../styles/servicepage.module.scss"

const ExteriorPaintingPage = () => {
  const data = useStaticQuery(graphql`
    query ExteriorQuery {
      exteriorHero: cloudinaryMedia(public_id: { eq: "pivot-painters-chicago/exteriorHero" }) {
        secure_url
      }
      imageRevealLeft: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/exteriorSlideLeft" }
      ) {
        secure_url
      }
      imageRevealRight: cloudinaryMedia(
        public_id: { eq: "pivot-painters-chicago/exteriorSlideRight" }
      ) {
        secure_url
      }
    }
  `)
  return (
    <Layout>
      <SEO
        title="Exterior Painting Chicago | House Painters"
        description="Exterior Painting in Chicago with Pivot Painters, we keep your home looking clean and maintained! Get a quote for your home today!"
      />
      <Hero
        name="Transform Your Home"
        subtitle="Exterior Painting"
        src={data.exteriorHero.secure_url}
      />
      <div className={ServicePageStyles.servicePageContainer}>
        <div className={ServicePageStyles.textContent}>
          <h3>Exterior Painting Done Right.</h3>
          Exterior painting is one of the most{" "}
          <strong>
            critical ways you can protect your investment and equity in your
            home.
          </strong>{" "}
          A fresh coat of paint can not only help the curb appeal of your
          property, but can help with long-term care and protection from the
          harsh Chicago weather.
          <br></br>
          <br></br>
          Whether you have vinyl siding, stucco, wood siding, or just need the
          trim touched up,{" "}
          <strong>our experienced painters will get the job done.</strong>
          <br></br>
          <br></br>
        </div>
        <TwentyTwenty
          left={
            <img
              src={data.imageRevealLeft.secure_url}
              className={ServicePageStyles.sliderImage}
            />
          }
          right={
            <img
              src={data.imageRevealRight.secure_url}
              className={ServicePageStyles.sliderImage}
            />
          }
          slider={
            <div className={ServicePageStyles.slider}>
              <div className={ServicePageStyles.sliderIcon}>
                <i className="fas fa-arrows-alt-h"></i>
              </div>
            </div>
          }
        />
        <div className={ServicePageStyles.textContent}>
          <h3>Why Hire Pivot Painters?</h3>
          We all know how crazy the weather in Chicago can be over the course of
          a year and nobody quite experiences it the same as your home which is
          why it's important to{" "}
          <strong>
            keep up with maintaining the condition and appearance of your home.
          </strong>{" "}
          <br></br>
          <br></br>
          Keep your home's outside curb appeal looking fresh and inviting with
          the help of{" "}
          <strong>Pivot Painters Residential Exterior Services.</strong>
          <br></br>
          <ul>
            <li>
              <i className="fas fa-brush"></i>
              <p>Trusted And Experienced Professionals</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Use Premium Quality Materials</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Constant And Reliable Communication</p>
            </li>
            <li>
              <i className="fas fa-brush"></i>
              <p>Craftsmanship Warranty</p>
            </li>
          </ul>
        </div>

        <OptInForm></OptInForm>
        <Reviews></Reviews>
      </div>
    </Layout>
  )
}

export default ExteriorPaintingPage
